import axios from 'axios'
import { useRouter, usePathname } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'

const REQUEST_TIMEOUT = 10000

export const useHealth = () => {
  const lastRequest = useRef<number>()
  const apiUrl = process.env.NEXT_PUBLIC_SPREE_API_HOST

  const router = useRouter()
  const path = usePathname()
  const [isInitialLoading, setIsInitialLoading] = useState(false)
  const checkHealth = async () => {
    try {
      const health = await axios.get(`${apiUrl}/health`)
      
      setIsInitialLoading(false)
      if (health.status !== 200 && !path?.includes('maintenance')) {
        router.push('/maintenance')
      }
      if (health.status === 200 && path?.includes('maintenance')) {
        router.push('/')
      }
    } catch (e) {
      setIsInitialLoading(false)
      if (
        !path?.includes('maintenance') &&
        e.message !== 'Request aborted'
      ) {
        router.push('/maintenance')
      }
    }
  }

  useEffect(() => {
    let interval: NodeJS.Timer
    const clickCallback = () => {
      if (
        !lastRequest.current ||
        Date.now() - lastRequest.current > REQUEST_TIMEOUT
      ) {
        checkHealth()
        lastRequest.current = Date.now()
      }
    }

    clickCallback()

    if (path?.includes('maintenance')) {
      interval = setInterval(() => checkHealth(), 30000)
    } else if (globalThis) {
      globalThis.addEventListener('click', clickCallback)
    }

    return () => {
      if (globalThis) {
        globalThis.removeEventListener('click', clickCallback)
      }
      clearInterval(interval)
    }
  }, [path])

  return { isInitialLoading }
}
